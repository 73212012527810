import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
	table: {
		"& [class$=p-datatable]": {
			position: "relative"
		},
		"& [class=p-datatable-wrapper]": {
			overflowX: "auto"
		},
		"& table": {
			minWidth: `calc(${theme.maxScreenWidth} - 100px)`
		},
		"& [class$=p-sortable-column] > span": {
			display: "inline",
			fontWeight: "700",
			fontSize: "16px"
		},
		"& [class$=p-highlight] > span": {
			display: "inline",
			fontWeight: "700",
			fontSize: "16px"
		},
		"& [class$=sub-header] > span": {
			display: "inline",
			fontSize: "12px"
		},
		"& th": {
			textAlign: "center",
			verticalAlign: "bottom",
			fontSize: "14px"
		},
		"& td": {
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			userSelect: "none"
		},
		"& .p-datatable-tbody > tr:hover": {
			background: theme.palette.bodyBackground
		},
		"& th > span": {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			fontWeight: "500",
			width: "100%"
		},
		"& tr:nth-child(1) th": {
			background: "transparent"
		}
	},
	tableColumn: {
		textAlign: "center",
		verticalAlign: "bottom"
	},
	currentPageReport: {
		padding: "0px 0px !important",
		fontFamily: "inherit",
		fontSize: "14px"
	},
	iconButton: {
		background: theme.palette.background,
		padding: "4px",
		width: "fit",
		height: "fit",
		cursor: "pointer",
		border: "none",
		transition: "background-color, transform .1s ease-in-out",
		"&:hover": {
			background: theme.palette.faintGray,
			boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.2)",
			backfaceVisibility: "hidden"
		},
		"&:active": {
			transform: "scale(0.95)"
		}
	},
	outlined: {
		fontSize: "16px",
		border: `2px solid ${theme.palette.transparent}`,
		padding: "4px",
		borderRadius: "50%"
	},
	overlayPanel: {
		overflowX: "hidden",
		maxHeight: "300px",
		minWidth: "280px",
		"@media (max-width: 768px)": {
			maxHeight: "200px"
		},
		maxWidth: "300px",
		overflowY: "auto",
		borderRadius: "12px",
		"& ul": {
			listStyle: "none",
			padding: "0",
			marginTop: "0px"
		}
	},
	listItem: {
		display: "flex",
		flexDirection: "row",
		padding: "12px 6px",
		borderTop: `1px solid ${theme.palette.lightGray}`
	},
	listTitle: {
		marginTop: "8px",
		marginBottom: "12px",
		fontWeight: "500",
		fontSize: "18px"
	}
}));
