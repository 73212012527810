import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "components/forms/Header";
import useStyle from "./useStyle";
import UnitPage from "components/forms/UnitPage";
import PricingMatrixPage from "components/forms/PricingMatrixPage";
import routes from "components/routes";
import SignOut from "components/forms/SignOut";
import AzureADIdentityProvider from "components/AzureADIdentityProvider";
import PropertiesPage from "components/forms/PropertiesPage";
import UnitAvailabilityPage from "components/forms/UnitAvailabilityPage";
import RentSummaryPage from "components/forms/RentSummaryPage";
import ReportsPage from "components/forms/ReportsPage";
import InvalidPage from "components/forms/InvalidPage";
import UserAddPage from "components/forms/UserAddPage";
import UserEditPage from "components/forms/UserEditPage";
import UserAdminPage from "components/forms/UserAdminPage";
import ExceptionsPage from "components/forms/ExceptionsPage/index";
import UnitGroupAdminPage from "components/forms/UnitGroupAdminPage/UnitGroupMapping";
import ManageUnitGroupAdmin from "components/forms/UnitGroupAdminPage/UnitGroupMapping/ManageUnitGroup";
import UnassignedUnitTypes from "components/forms/UnitGroupAdminPage/Unassigned Unit Types/UnassignedUnitTypes";
import BedroomsAdminPage from "components/forms/UnitGroupAdminPage/Bedroom/Bedrooms";
import UnitGroupMasterAdminPage from "components/forms/UnitGroupAdminPage/UnitGroupMaster/UnitGroupMaster";
import ClassificationsAdminPage from "components/forms/UnitGroupAdminPage/Classifications/Classifications";
import CreateClassificationAdminPage from "components/forms/UnitGroupAdminPage/Classifications/CreateClassification";
import ManageUnitGroupMaster from "components/forms/UnitGroupAdminPage/UnitGroupMaster/ManageUnitGroupMaster";
import ManageBedroomAdminPage from "components/forms/UnitGroupAdminPage/Bedroom/ManageBedroom";
import PricingAutomationPage from "components/forms/PricingAutomation";
import { Drawer } from "components/forms/Drawer";
import AdminPage from "components/forms/AdminPage";

import StaleUnitPage from "components/forms/StaleUnitPage";
import ConcessionsReportPage from "components/forms/ConcessionsReportPage";
import PricingAdjustmentSummaryReportPage from "components/forms/PricingAdjustmentSummaryReportPage";
import AutomationValidationReportPage from "components/forms/AutomationValidationReportPage";
import { LeaseTermAdminPage } from "components/forms/LeaseTermAdminPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { OverrideReasonAdminPage } from "components/forms/OverrideReasonAdminPage";


const queryClient = new QueryClient({
	defaultOptions: {
		queries: { retry: 1, refetchOnReconnect: false, refetchOnWindowFocus: false }
	}
});

const Main: React.FC = function () {
	const classes = useStyle();

	return (
		<AzureADIdentityProvider>
			<QueryClientProvider client={queryClient}>
				<Switch>
					<Route path="*">
						<div className={classes.main}>
							<div className={classes.logoContent}>
								<Header />
							</div>
							<div className={classes.pContentLayout}>
								<Drawer />
								<div className={classes.pMainContent}>
									<Switch>
										<Route path={routes.pricingAutomation.default}>
											<div className="pricingAutomation">
												<PricingAutomationPage />
											</div>
										</Route>
										<Route exact path={routes.home}>
											<Redirect to={routes.pricingAutomation.default} />
										</Route>
										<Route exact path={routes.units()} component={UnitPage} />
										<Route exact path={routes.properties()} component={PropertiesPage} />
										<Route exact path={routes.exceptions()} component={ExceptionsPage} />
										<Route exact path={routes.pricingMatrix()} component={PricingMatrixPage} />
										<Route exact path={routes.signOut} component={SignOut} />
										<Route exact path={routes.reports} component={ReportsPage} />
										<Route exact path={routes.unitAvailabilityPage} component={UnitAvailabilityPage} />
										<Route exact path={routes.staleUnitPage} component={StaleUnitPage} />
										<Route exact path={routes.concessionsReportPage} component={ConcessionsReportPage} />
										<Route
											exact
											path={routes.pricingAdjustmentSummaryReportPage}
											component={PricingAdjustmentSummaryReportPage}
										/>
										<Route
											exact
											path={routes.automationValidationReportPage}
											component={AutomationValidationReportPage}
										/>
										<Route exact path={routes.rentSummary} component={RentSummaryPage} />
										<Route
											exact
											path={routes.unitGroupAdmin.updateClassification()}
											component={CreateClassificationAdminPage}
										/>
										<Route
											exact
											path={routes.unitGroupAdmin.updateBedroom()}
											component={ManageBedroomAdminPage}
										/>
										<Route
											exact
											path={routes.unitGroupAdmin.createUnitGroupMaster()}
											component={ManageUnitGroupMaster}
										/>
										<Route
											exact
											path={routes.unitGroupAdmin.updateUnitGroupMaster()}
											component={ManageUnitGroupMaster}
										/>
										<Route
											exact
											path={routes.unitGroupAdmin.createUnitGroup()}
											component={ManageUnitGroupAdmin}
										/>
										<Route
											exact
											path={routes.unitGroupAdmin.updateUnitGroup()}
											component={ManageUnitGroupAdmin}
										/>
										<Route exact path={routes.unitGroupAdmin.unitgroup()} component={UnitGroupAdminPage} />
										<Route exact path={routes.leaseTermAdmin.leaseTerm} component={LeaseTermAdminPage} />
										<Route exact path={routes.overrideReasonAdmin.overrideReason} component={OverrideReasonAdminPage} />
										<Route
											exact
											path={routes.unitGroupAdmin.unassignedUnitTypes}
											component={UnassignedUnitTypes}
										/>
										<Route exact path={routes.unitGroupAdmin.bedrooms} component={BedroomsAdminPage} />
										<Route exact path={routes.unitGroupAdmin.createBedroom} component={ManageBedroomAdminPage} />
										<Route
											exact
											path={routes.unitGroupAdmin.unitGroupMasters}
											component={UnitGroupMasterAdminPage}
										/>
										<Route
											exact
											path={routes.unitGroupAdmin.classifications}
											component={ClassificationsAdminPage}
										/>
										<Route
											exact
											path={routes.unitGroupAdmin.createClassification}
											component={CreateClassificationAdminPage}
										/>
										<Route exact path={routes.admin.default} component={AdminPage} />
										<Route exact path={routes.admin.users} component={UserAdminPage} />
										<Route exact path={routes.admin.addUser} component={UserAddPage} />
										<Route exact path={routes.admin.editUser()} component={UserEditPage} />
										<Route path={routes.tokenCallback}>
											<Redirect from={routes.tokenCallback} to={routes.home}></Redirect>
										</Route>
										<Route component={InvalidPage} />
									</Switch>
								</div>
							</div>
						</div>
					</Route>
				</Switch>
			</QueryClientProvider>
		</AzureADIdentityProvider>
	);
};
export default Main;
