import React, { useContext } from "react";
import Menu from "../Menu";
import Page from "components/layouts/Page";
import useStyle from "./useStyle";
import Tile from "components/controls/Tile";
import RequirePermission from "../RequirePermission";
import userGroupImageAsset from "assets/Tile-User-Group.jpg";
import propertyImageAsset from "assets/Tile-Property.jpg";
import billiardImageAsset from "assets/Tile-Billiard.jpg";
import regulationsImageAsset from "assets/Tile-Regulations.jpg";
// import rentSummaryImageAsset from "assets/Tile-Rent-Summary.jpg";
import { IdentityContext, Identity } from "components/IdentityProvider";
import Spinner from "components/controls/Spinner";
import routes from "components/routes";
import constants from "utils/constants";

const AdminPage: React.FC = function () {
	const classes = useStyle();

	const identity: Identity = useContext(IdentityContext);

	if (!identity || !identity.isLoaded) {
		return <Spinner isVisible={true} />;
	}

	if (identity.permissions.userId === null) {
		return <h5>Your User does not exist in the database.</h5>;
	} else if (!identity.permissions.allowed) {
		return <h4>You do not have access to this application.</h4>;
	}

	return (
		<Page menu={<Menu title={constants.menuTitle.admin} />}>
			<div className={classes.pageWrapper}>
				<div className={classes.row}>
					<RequirePermission permissions={[constants.permissions.canViewAdminArea]} accessDenied="page">
						<Tile
							title={constants.menuTitle.manageUsers}
							href={routes.admin.users}
							assetName={userGroupImageAsset}
							visible={identity.permissions.canManageUsers}
						/>
						<Tile
							title={constants.menuTitle.unitGroupsAdminTitlecase}
							href={routes.unitGroupAdmin.unitgroup()}
							assetName={propertyImageAsset}
							visible={identity.permissions.canViewAdminArea}
						/>
						<Tile
							title={constants.menuTitle.leaseTermAdminTitlecase}
							href={routes.leaseTermAdmin.leaseTerm}
							assetName={billiardImageAsset}
							visible={identity.permissions.canViewAdminArea}
						/>
						<Tile
							title={constants.menuTitle.overrideReasonAdminTitlecase}
							href={routes.overrideReasonAdmin.overrideReason}
							assetName={regulationsImageAsset}
							visible={identity.permissions.canViewAdminArea}
						/>
					</RequirePermission>
				</div>
			</div>
		</Page>
	);
};

export default AdminPage;
