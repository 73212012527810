import React, { useState } from "react";
import useStyle from "./useStyle";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import useFirstLoadEffect from "utils/useMountEffect";
import { PricingAdjustmentSummaryReportRequest } from "api";
import { ProgressSpinner } from "primereact/progressspinner";
import constants from "utils/constants";
import { formatDateTime } from "utils/miscUtils";
import { ColumnType } from "components/controls/DataTable/types";
import SecondaryButton from "components/controls/SecondaryButton";
import { DataTable } from "components/controls/DataTable";
import { useSearchState } from "components/controls/DataTable/SearchState";

const AutomationValidationReportPage: React.FC = function () {
	const classes = useStyle();
	const { searchState, onPage, onSort } = useSearchState();
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.automationValidationReportPageState.filterOptions,
		automationValidationReports: appState.automationValidationReportPageState.automationValidationReports,
		isLoading: appState.automationValidationReportPageState.isLoading,
		...appState
	};
	const actions = {
		...appActions.automationValidationReportPage,
		...appActions
	};

	//does this not need to use the store because we defined directly here?
	interface LocalState {
		currentFilters: PricingAdjustmentSummaryReportRequest;
		appliedFilters: PricingAdjustmentSummaryReportRequest;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: {},
		appliedFilters: {}
	});

	useFirstLoadEffect(() => {
		actions.getAutomationRuns();
	});

	const getDownloadLink = (e: string) => {
		actions.getCsvForRun(e);
	}

	const columns: ColumnType[] = [
		{ name: "Automation Run Time", subColumns: [{field: "startTime", sortable: true, body: (row: any) => formatDateTime(row["startTime"]) }]},
		{ name: "", subColumns: [{ field:"id", body: (row: any) => <SecondaryButton title={"Download"} type={"notice"} onClick={() => getDownloadLink(row["id"])} /> , sortable: false}]}
	];

	const displayReport = () => {
		if (pageState.automationValidationReportPageState.automationValidationReports) {
			return (
				<DataTable 
				columns={columns} 
				data={[...pageState.automationValidationReportPageState.automationValidationReports]}
				loading={pageState.automationValidationReportPageState.isLoading}
				searchState={searchState}
				totalRecords={pageState.automationValidationReportPageState.automationValidationReports.length}
				onPage={onPage}
				onSort={onSort}
				sortField="startTime"
				scrollable={true}
				tableName={"Automation Validation"} 
				lazy={false} />
			);
		}
		else {
			return <></>;
		}
	};

	return (
		<Page menu={<Menu title={constants.reportTitle.automationValidation} />}>
			<div id="contentContainer" className={classes.contentContainer}>
				{pageState.isLoading ? <ProgressSpinner /> : <div className={classes.pageHeading}> {displayReport()}</div>}
			</div>
		</Page>
	);
};

export default AutomationValidationReportPage;
