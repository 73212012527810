import { UserPermissions } from "api";
import { MessageSeverity } from "./messageUtils";

export const MessageAreaMessages = {
	Error: "Error",
	Success: "Success",
	ConfigurableParamsSuccessMessage: "Configurable Parameters have been saved",
	DeleteParamSuccessMessage: "parameters have been deleted",
	DeleteColumnParamSuccessMessage: "value has been deleted",
	DeleteParamErrorMessage: "parameters have not been deleted",
	DeleteColumnParamErrorMessage: "value has not been deleted",
	ConfigurableParamsErrorMessage: "Configurable Parameters have not been saved",
	PriceAdjustmentSuccessMessage: "Your changes have been saved",
	PriceAdjustmentErrorMessage: "Your changes have not been saved",
	ApiDownTitle: "Network Issue",
	ApiDownMessage:
		"The Web Service is unavailable because of network issues, Please try again in a few minutes.  If the problem persists, please reach out to the Pricing Support Team.",
	DuplicateUnitGroupMasterTitle: "Duplicate Unit Group Master",
	DuplicateUnitGroupMasterMessage:
		"You can't add two unit group masters with the same name and bedroom count. Change one and try again."
};

export interface MessageAreaObject {
	title: string;
	message: string;
	severity: MessageSeverity;
	autoHide: boolean;
}

export const permissions = {
	canManageUsers: "canManageUsers" as keyof UserPermissions,
	canManagePricing: "canManagePricing" as keyof UserPermissions,
	canManageUnitGroups: "canManageUnitGroups" as keyof UserPermissions,
	canManageLeaseTerms : "canManageLeaseTerms" as keyof UserPermissions,
	canViewAllProperties: "canViewAllProperties" as keyof UserPermissions,
	canViewAllExceptions: "canViewAllExceptions" as keyof UserPermissions,
	canViewAllDivisions: "canViewAllDivisions" as keyof UserPermissions,
	canViewAllAdminUnitGroups: "canViewAllAdminUnitGroups" as keyof UserPermissions,
	canViewAllManageAdminUnitGroups: "canViewAllManageAdminUnitGroups" as keyof UserPermissions,
	canViewAllUnassignedUnitTypes: "canViewAllUnassignedUnitTypes" as keyof UserPermissions,
	canViewPropertiesArea: "canViewPropertiesArea" as keyof UserPermissions,
	canViewUnitsArea: "canViewUnitsArea" as keyof UserPermissions,
	canViewReportsArea: "canViewReportsArea" as keyof UserPermissions,
	canViewAdminArea: "canViewAdminArea" as keyof UserPermissions,
	canViewAllPricingAutomation: "canViewAllPricingAutomation" as keyof UserPermissions,
	canManageAutomationConfigurations: "canManageAutomationConfigurations" as keyof UserPermissions,
};

export const sort = {
	asc: "asc",
	desc: "desc",
	ascOrder: 1,
	desOrder: -1
};

export const rowsPerPageOptions: number[] = [10, 20, 50];

export const menuTitle = {
	home: "Home",
	exceptions: "Exceptions",
	properties: "Properties",
	pricingAutomation: "Pricing Automation",
	secondaryReview: "Secondary Review",
	units: "Units",
	unitAvailability: "Unit Availability",
	reports: "Reports",
	admin: "Admin",
	users: "Users",
	signOut: "Sign Out",
	pricingMatrix: "Pricing Matrix",
	manageUsers: "Manage Users",
	rentSummary: "Rent Summary",
	unitGroups: "Unit Groups",
	unitGroupsAdmin: "UNIT GROUP Admin",
	unitGroupsAdminTitlecase: "Unit Group Admin",
	leaseTermAdminTitlecase: "Lease Term Admin",
	overrideReasonAdminTitlecase: "Override Reason Admin",
	unitGroupMapping: "Unit Group Mapping",
	manageUnitGroupsAdminCreate: "Create",
	manageUnitGroupsAdminUpdate: "Edit",
	manageUnitGroupsMasterCreate: "Create",
	manageUnitGroupsMasterUpdate: "Edit",
	unassignedUnitTypes: "UnAssigned Unit Types",
	unitGroupMaster: "Unit Groups",
	createUnitGroupMaster: "Create Unit Group Master",
	bedrooms: "Bedrooms",
	createBedroom: "Create",
	updateBedroom: "Edit",
	classifications: "Unit Group Classifications",
	createClassification: "Create",
	editClassification: "Edit",
	invalidPage404: "Invalid Page (404)",
	exclusions: "Exclusions",
	systemAutomated: "System Automated",
	configureAutomation: "Configure Automation",
	staleUnit: "Stale Unit",
	concessionsReport: "Concessions",
	pricingAdjustmentSummaryReport: "Pricing Adjustment Summary"
};

export const reportTitle = {
	automationValidation: "Automation Validation"
};

export const filters = {
	division: "division",
	county: "county",
	collection: "collection",
	property: "property",
	unitGroup: "unitgroup"
};

export const filterLabels = {
	recommendActions: "Recommend Action",
	division: "Division",
	county: "County",
	property: "Property",
	reviewReason: "Review Reason",
	reviewReasonDropdown: "Review Reason Dropdown",
	unitGroup: "Unit Group",
	hierarchyLevels: "Hierarchy Levels",
	manualExclusion: "Manually Excluded"
};

export const pageAction = {
	create: "create",
	update: "update",
	delete: "delete"
};

export const pageName = {
	unitGroupAdmin: "unitGroupAdmin",
	bedroom: "Bedroom"
};

export const buttonLabel = {
	ok: "OK",
	no: "NO",
	save: "SAVE",
	cancel: "CANCEL",
	delete: "DELETE",
	reviewAll: "REVIEW ALL"
};

export const dialogType = {
	save: "SAVE",
	delete: "DELETE"
};

export const message = {
	notFound: "Unit Group not found.",
	dialog: {
		save: {
			title: "SAVE ERROR",
			body: "Save was unsuccessful. Please try again."
		},
		delete: {
			title: "DELETE UNIT GROUP",
			body: "Are you sure you want to delete? All unit types will be unassigned and you will need to reassign them to other unit groups."
		}
	}
};

export const titleSection = {
	successfulAutomation: "SUCCESSFUL AUTOMATION",
	successfullyAutomated: "SUCCESSFULLY AUTOMATED",
	userModifiedUnitGroups: "USER MODIFIED UNIT GROUPS",
	unitGroupsExcludedFromAutomation: "UNIT GROUPS EXCLUDED FROM AUTOMATION",
	priceAdjustment: "PRICE ADJUSTMENT",
	exclusion: "EXCLUSIONS",
	excluded: "EXCLUDED",
	recommendation: "RECOMMENDATION",
	notes: "NOTES",
	secondaryReview: "SECONDARY REVIEW",
	toReview: "TO REVIEW",
	completed: "COMPLETED",
	atAGlance: {
		title: "AT A GLANCE",
		rigth: { averageAskingPrice: "Average Asking Price:", averagePriceChange: "Average Price Change:" }
	}
};

export const extraContent = {
	totalPortfolio: "TOTAL PORTFOLIO",
	totalProperties: "TOTAL PROPERTIES"
};

/**
 * Will be removed when not required.
 */
export const featureToggle = {
	viewExceptions: false,
	viewUnitGroupsAdmin: true,
	viewPricingAutomation: false
};

export const cardTitle = {
	scoreCard: "ATR SCORE CARD - TOTAL PORTFOLIO",
	automationCard: "AUTOMATION",
	marketPositioning: "MARKET POSITIONING",
	leasingActivity: "LEASING ACTIVITY",
	leadsAndNetApps: "# OF LEADS AND NET APPS BY PROPERTY",
	concessions: "CONCESSIONS"
};

export const tableContent = {
	configurableParametersEmpty: "No configurable parameters found",
	successfulAutomationTitle: "SUCCESSFUL AUTOMATION",
	successfulAutomationEmpty: "No successful automation found",
	successfullyAutomatedTitle: "SUCCESSFULLY AUTOMATED",
	successfullyAutomatedEmpty: "No successfully automated found",
	userModifiedFloorPlansTitle: "USER MODIFIED FLOOR PLANS",
	userModifiedFloorPlansEmpty: "No user modified floor plans found",
	unitGroupsExcludedFromAutomationTitle: "UNIT GROUPS EXCLUDED FROM AUTOMATION",
	unitGroupsExcludedFromAutomationEmpty: "No unit groups excluded from automation found",
	exclusionsTitle: "EXCLUSIONS",
	exclusionsEmpty:
		"No exclusions found. You either need to select a Property, or you do not have permission to view any exclusions",
	completedSRTitle: "COMPLETED SECONDARY REVIEW",
	completedSREmpty:
		"No completed secondary review found. You either need to select a Property, or you do not have permission to view any completed secondary review",
	toReviewSRTitle: "TO REVIEW SECONDARY REVIEW",
	toReviewSREmpty:
		"No to review secondary review found. You either need to select a Property, or you do not have permission to view any to review secondary review"
};

export const compPremium = {
	local: "Local Comp Premium (Discount)",
	submarket: "Submarket Comp Premium (Discount)"
};

export const popUpContent = {
	configureAutomation: {
		title: "Create New Override"
	}
};

export default {
	tableContent,
	cardTitle,
	MessageAreaMessages,
	menuTitle,
	permissions,
	sort,
	rowsPerPageOptions,
	filters,
	featureToggle,
	pageName,
	pageAction,
	buttonLabel,
	dialogType,
	message,
	titleSection,
	extraContent,
	filterLabels,
	compPremium,
	popUpContent,
	reportTitle
};
