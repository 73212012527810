import Page from "components/layouts/Page";
import React, { useContext, useEffect, useState } from "react";
import Menu from "../Menu";
import Spinner from "components/controls/Spinner";
import useMessageArea from "components/useMessageArea";
import useStyle from "./useStyle";
import { Identity, IdentityContext } from "components/IdentityProvider";
import RequirePermission from "../RequirePermission";
import PrimaryButton from "components/controls/PrimaryButton";
import constants from "utils/constants";
import { responseHandlers, useRemoteData } from "components/useRemoteData";
import { useApiEndpoint } from "components/useApiEndpoint";
import { ApiException, OverrideReason, OverrideReasonAdminForm, OverrideReasonAdminFormReason, OverrideReasonClient } from "api/pricing-platform-api";
import "./_stylish.scss";
import { DataTableStandard } from "components/controls/DataTableStandard";
import { ColumnDefinition } from "components/controls/createTable";
import { Column } from "primereact/column";
import Checkbox from "components/controls/Checkbox";
import Dialog from "components/controls/Dialog";
import Input from "components/controls/Input";
import { formatDateTime } from "utils/miscUtils";
import Spacer from "components/controls/Spacer";
import { Button } from "primereact/button";
import UnsavedChangesPrompt from "components/controls/UnsavedChangesPrompt";
import SecondaryButton from "components/controls/SecondaryButton";
import { Card } from "primereact/card";

export const OverrideReasonAdminPage = () => {
	const classes = useStyle();
	const identity: Identity = useContext(IdentityContext);
	const [messageAreaState, setMessageBasedOnForm, setMessageBasedOnApiException] = useMessageArea();
	const [pageState, setPageState] = useState({
		isLoading: false,
		isChanged: false,
		newOverrideReason: {} as OverrideReason
	});
	const [createPopupState, setCreatePopupState] = useState(false);
	const [formState, setFormState] = useState<OverrideReasonAdminForm>({});

	const [loadDataState, loadData] = useRemoteData(useApiEndpoint(OverrideReasonClient, "getOverrideReasons"), [], {
		onChange: (form) => {
			setFormState(form ?? {});
			setPageState({ ...pageState, isLoading: false, isChanged: false });
		},
		onError: (error: ApiException) => {
			responseHandlers.use({
				"400": (ex: ApiException) => {
					const form: OverrideReasonAdminForm = ex.result;
					setMessageBasedOnForm(form);
					setFormState(form ?? {});
					setPageState({ ...pageState, isLoading: false, isChanged: true });
				},
				"401": (ex: ApiException) => {
					setPageState({ ...pageState, isLoading: false, isChanged: true });
					setMessageBasedOnApiException(ex);
				},
				_: (ex) => {
					setPageState({ ...pageState, isLoading: false, isChanged: true });
					setMessageBasedOnApiException(ex);
				}
			})(error);
		}
	});

	const [submitState, submit] = useRemoteData(
		useApiEndpoint(OverrideReasonClient, "updateOverrideReasons"),
		[formState],
		{
			onChange: (form) => {
				setFormState(form ?? {});
				setPageState({ ...pageState, isLoading: false, isChanged: false });
				setMessageBasedOnForm(form);
			},
			onError: (error: ApiException) => {
				responseHandlers.use({
					"400": (ex: ApiException) => {
						const form: OverrideReasonAdminForm = ex.result;
						setMessageBasedOnForm(form);
						setFormState(form ?? {});
						setPageState({ ...pageState, isLoading: false, isChanged: true });
					},
					"401": (ex: ApiException) => {
						setPageState({ ...pageState, isLoading: false, isChanged: true });
						setMessageBasedOnApiException(ex);
					},
					_: (ex) => {
						setPageState({ ...pageState, isLoading: false, isChanged: true });
						setMessageBasedOnApiException(ex);
					}
				})(error);
			}
		}
	);

	const [submitNewReasonState, submitNewReason] = useRemoteData(
		useApiEndpoint(OverrideReasonClient, "addOverrideReasons"),
		[pageState.newOverrideReason],
		{
			onChange: (form) => {
				setFormState(form ?? {});
				setPageState({ ...pageState, isLoading: false, isChanged: false });
				setMessageBasedOnForm(form);
				handleHideOverridePopup();
			},
			onError: (error: ApiException) => {
				responseHandlers.use({
					"400": (ex: ApiException) => {
						const form: OverrideReasonAdminForm = ex.result;
						setMessageBasedOnForm(form);
						setPageState({ ...pageState, isLoading: false });
					},
					"401": (ex: ApiException) => {
						setPageState({ ...pageState, isLoading: false });
						setMessageBasedOnApiException(ex);
					},
					_: (ex) => {
						setPageState({ ...pageState, isLoading: false });
						setMessageBasedOnApiException(ex);
					}
				})(error);
			}
		}
	);

	useEffect(() => {
		loadData([]);
	}, []);

	const handleSubmit = () => {
		setPageState({ ...pageState, isLoading: true });
		submit([{ ...formState }]);
	};

	const handleClearOverrideReason = () => {
		setPageState({ ...pageState, newOverrideReason: {} });
		handleHideOverridePopup();
	};

	const handleCreateOverrideReason = () => {
		setPageState({ ...pageState, isLoading: true, newOverrideReason: {} });
		submitNewReason([pageState.newOverrideReason]);
	};
	const handleShowOverridePopup = () => {
		setCreatePopupState(true);
	};

	const handleHideOverridePopup = () => {
		setCreatePopupState(false);
	};

	const formatCheckboxColumn =
		<T extends Record<string, OverrideReason>>(fieldName: keyof OverrideReason) =>
		(row: OverrideReason) => {
			const isChecked = row[fieldName];

			return (
				<Checkbox
					checked={isChecked === true}
					label=""
					onChange={(checked: any) => {
						const newDefaultsRow = {
							...row
						};
						const value: any = checked === true;
						newDefaultsRow[fieldName] = value;
						newDefaultsRow["isChanged"] = true;
						if (!formState.overrideReasons) {
							return;
						}
						const newState = [...formState.overrideReasons];
						const index = formState.overrideReasons.indexOf(row);
						newState[index] = newDefaultsRow;

						setFormState({ ...formState, overrideReasons: newState });
						setPageState({ ...pageState, isChanged: true });
					}}
				/>
			);
		};

	const formatLocalDateTimeColumn =
		<T extends Record<string, OverrideReason>>(fieldName: keyof OverrideReason) =>
		(row: OverrideReason) => {
			const datetime = row[fieldName];
			let date: Date | undefined;
			if (typeof datetime == "string") {
				date = new Date(datetime);
			} else if (datetime instanceof Date) {
				date = datetime;
			}

			if (!date) {
				return;
			}

			const dateString = formatDateTime(date);
			return <>{dateString}</>;
		};

	const columns: ColumnDefinition<any, keyof OverrideReasonAdminFormReason>[] = [
		{ header: "Name", field: "name", sortable: true, headerStyle:{"width": "550px"}
		 } as ColumnDefinition<
			any,
			keyof OverrideReasonAdminFormReason
		>,
		{
			header: "Disabled",
			field: "isDeleted",
			sortable: true,
			body: formatCheckboxColumn("isDeleted"),
			headerStyle:{"width": "150px", "text-align": "center"},
			bodyStyle:{"text-align" : "center"},
		} as ColumnDefinition<any, keyof OverrideReasonAdminFormReason>,
		{
			header: "Created",
			field: "createdDate",
			body: formatLocalDateTimeColumn("createdDate"),
			sortable: true
		} as ColumnDefinition<any, keyof OverrideReasonAdminFormReason>,
		{
			header: "Last Updated",
			field: "lastUpdatedDate",
			body: formatLocalDateTimeColumn("lastUpdatedDate"),
			sortable: true
		} as ColumnDefinition<any, keyof OverrideReasonAdminFormReason>,
		{ header: "Last Updated By", field: "lastUpdatedBy", sortable: true } as ColumnDefinition<
			any,
			keyof OverrideReasonAdminFormReason
		>
	];

	const sortMetadata: any[] = [];
	sortMetadata.push({ field: "name", order: 1 });

	const [sortState, setSortState] = useState(sortMetadata);

	const addRowButton = () => {
		return <Button onClick={() => handleShowOverridePopup()} icon="pi pi-plus" />;
	};

	const getDataTable = () => {
		if (!formState.overrideReasons?.some((x) => x)) {
			return <></>;
		}

		return (
			<DataTableStandard
				data={formState.overrideReasons}
				lazy={false}
				className={"p-datatable-striped grey-header"}
				multiSortMeta={sortState}
				sortMode={"multiple"}
				onSort={(e) => setSortState([...e.multiSortMeta])}
				paginator		
				resizableColumns={false}		
				rows={10}
				paginatorLeft={addRowButton()}
				paginatorRight={<></>}
			>
				{columns.map((c, i) => (
					<Column key={i} {...c} />
				))}
			</DataTableStandard>
		);
	};

	return (
		<Page menu={<Menu title="OVERRIDE REASON ADMIN" />} className="unit-group-admin-page">
			{messageAreaState.messageArea}
			<Spinner isVisible={pageState.isLoading}></Spinner>
			<div className="top-action-area">
				<RequirePermission permissions={[constants.permissions.canManageUnitGroups]}>
					<UnsavedChangesPrompt isVisible={pageState.isChanged} />
					<Spacer />
					<PrimaryButton onClick={() => handleSubmit()} disabled={!pageState.isChanged} title="SAVE" />
				</RequirePermission>
			</div>
			<Spacer orientation="v"></Spacer>
			<Card>{getDataTable()}</Card>
			<div className="bottom-action-area"></div>
			<Dialog
				header={"CREATE OVERRIDE REASON"}
				footer={<></>}
				isVisible={createPopupState}
				onHide={() => handleHideOverridePopup()}
			>
				<Input
					value={pageState.newOverrideReason.name}
					onChange={(e: string) => setPageState({ ...pageState, newOverrideReason: { name: e } })}
				/>
				<div className={"create-action-area"}>
					<SecondaryButton type="notice" onClick={() => handleClearOverrideReason()} title="CANCEL" />
					<Spacer />
					<PrimaryButton onClick={() => handleCreateOverrideReason()} title="SAVE" />
				</div>
			</Dialog>
		</Page>
	);
};
